
/* STYLE CONTACT FORM 7 ERRORS */

$fontawesomeVersion:"Font Awesome 5 Free";

$okbackcolor:#28a745;
$errorbackcolor:#dc3545;
$textcolor:#fff;

.wpcf7-form {

    p{
        margin:0 !important;
    }

    .wpcf7-response-output.wpcf7-mail-sent-ok,
    .wpcf7-response-output.wpcf7-validation-errors,
    .wpcf7-response-output.wpcf7-mail-sent-ng,
    .wpcf7-response-output.wpcf7-aborted,
    .wpcf7-response-output.wpcf7-spam-blocked {
        border: 0;
        padding: 10px;
        margin:0;
        width: 100%;
        float: left;
        color:$textcolor;
        &::before{
            font: 26px/30px $fontawesomeVersion;
            margin-right: 15px;
            vertical-align: middle;
            font-weight: 900;
        }
    }
    .wpcf7-response-output.wpcf7-mail-sent-ok{
        background-color:$okbackcolor;
        &::before{
            content:'\f00c';
        }
    }
    .wpcf7-response-output.wpcf7-validation-errors, .wpcf7-response-output.wpcf7-mail-sent-ng, .wpcf7-response-output.wpcf7-aborted, .wpcf7-response-output.wpcf7-spam-blocked{
        background-color:$errorbackcolor;
        &::before{
            content:'\f057';
        }
    }

    .ajax-loader {
        background-image:none !important;
        margin:0 !important;
        padding:0 !important;
        animation: spin 1000ms infinite linear;
        transform-origin: 13px 15px;
        margin-top:5px !important;
        bottom:40px !important;
        right:110px !important;
        position:absolute !important;
        &::before{
            font: 26px/30px $fontawesomeVersion;
            font-weight: 900;
            content:'\f110';
            
        }
        
    }

}

#wpcf7-f49-o1{
    .ajax-loader{
        right: 35px !important;
        top: 45px;
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}